export enum ContentType {
  BUTTON = "Button",
  LINK = "Link",
  TAB = "Tab",
  DROPDOWN = "Dropdown",
  PAGE_OVERLAY = "Page Overlay",
}

export const contentInteractionClick = "Click";
export const contentInteractionPageOverlay = "Modal Open";

export interface EventContentInteraction {
  type: ContentType;
  interaction:
    | typeof contentInteractionClick
    | typeof contentInteractionPageOverlay;
}

export const eventContentButtonInterObj: EventContentInteraction = {
  type: ContentType.BUTTON,
  interaction: contentInteractionClick,
};

export const eventContentLinkInterObj: EventContentInteraction = {
  type: ContentType.LINK,
  interaction: contentInteractionClick,
};

export const eventContentDropdownInterObj: EventContentInteraction = {
  type: ContentType.DROPDOWN,
  interaction: contentInteractionClick,
};

export const eventContentTabInterObj: EventContentInteraction = {
  type: ContentType.TAB,
  interaction: contentInteractionClick,
};

export const eventContentPageOverlayInterObj: EventContentInteraction = {
  type: ContentType.PAGE_OVERLAY,
  interaction: contentInteractionPageOverlay,
};
