import React, { FC } from "react";
import { useIntl } from "react-intl";
import SvgVerifiedByDHL from "react-lib/es/icons/SvgVerifiedByDHL";
import messages from "./VerifiedByDhl.messages";
import * as S from "./VerifiedByDhl.styles";

interface VerifiedByDHlProps {
  className?: string;
}

export const VerifiedByDHl: FC<VerifiedByDHlProps> = ({ className }) => {
  const intl = useIntl();

  return (
    <S.VerifiedByDHLContainer
      className={className}
      data-testid="verified-by-dhl"
    >
      <S.StyledSVGWrapper>
        <SvgVerifiedByDHL />
      </S.StyledSVGWrapper>
      {intl.formatMessage(messages.verifiedByDhl)}
    </S.VerifiedByDHLContainer>
  );
};
