import { DHLI_ROLE, C2PORTAL_ROLE } from "constants/variables";
import SiteLogo from "components/atoms/SiteLogo";
import TopBar from "components/atoms/TopBar";
import ExternalLink from "components/molecules/ExternalLink/ExternalLink";
import UserTopBar from "components/organisms/User/UserTopBar";
import Auth from "containers/Auth";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { colors, media } from "react-lib";
import SVGWrapper from "react-lib/es/atoms/SVGWrapper";
import SvgVisibilityOn from "react-lib/es/icons/SvgVisibilityOn";
import { useDispatch, useSelector } from "react-redux";
import { getCorporatePartners } from "redux/userSettings/actions";
import { getReadOnly } from "redux/userSettings/selectors";
import styled from "styled-components";
import { getRem } from "utils/css";
import { getDHLiUrl, getC2PortalUrl } from "utils/links";
import BurgerMenu from "./Partials/BurgerMenu";
import ScopeSelector from "./Partials/ScopeSelector";
import SupportCenterTopBarButton from "./Partials/SupportCenterTopBarButton/SupportCenterTopBarButton";

export const messages = defineMessages({
  dhliPortal: {
    id: "dashboard.dhliPortal",
    defaultMessage: "DHLi Portal",
  },
  c2Portal: {
    id: "dashboard.c2Portal",
    defaultMessage: "C2 Portal",
  },
  readOnly: {
    id: "dashboard.readOnly",
    defaultMessage: "Read-only",
  },
});

const BurgerWrapper = styled.div`
  flex: 0 0 auto;
  padding-right: ${getRem(20)};
`;
const LogoWrapper = styled.div`
  flex: 1 1 auto;
`;
const UserWrapper = styled.div`
  flex: 0 0 auto;
`;

const ReadOnlyWrapper = styled.div`
  margin-right: 0.5rem;

  ${media.from("sm")} {
    margin-right: 1.25rem;
  }
`;

const ReadOnlyIconWrapper = styled(SVGWrapper)`
  margin-right: 0.25rem;

  ${media.from("sm")} {
    margin-right: 0.625rem;
  }
`;

const ReadOnlyMessageContainer = styled.span`
  display: none;
  font-family: Delivery;
  font-size: 0.875rem;
  font-weight: bold;
  color: ${colors.webBlack};
  line-height: 1.5rem;

  ${media.from("xs")} {
    display: inline-block;
  }

  ${media.from("sm")} {
    font-size: 1rem;
  }
`;

const UserIconSeparator = styled.div`
  margin: ${getRem(20)} ${getRem(20)} ${getRem(20)} 0;
  border-left: ${getRem(2)} solid #d1c184;
`;

export const TopBarWithAuth = ({
  onMenuClick,
  onLogoClick,
  onSearchClickMobile,
  onUserClick,
  onSearchTextChange,
  onSearchEnter,
  onSearchReset,
  belowLg,
  ...otherProps
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const currentUserRoles = useSelector((state) => state.auth?.currentUserRoles);
  const isUserLoggedIn = useSelector((state) => state.auth?.isUserLoggedIn);
  const isDemo = useSelector(
    (state) => state.userSettings.visibilityScope?.demo
  );
  const isReadOnly = useSelector(getReadOnly);

  useEffect(() => {
    if (isUserLoggedIn) {
      dispatch(
        getCorporatePartners({
          isDemoMode: isDemo === undefined ? true : isDemo,
        })
      );
    }
    // eslint-disable-next-line
  }, [isUserLoggedIn]);

  return (
    <TopBar {...otherProps} isMobile={belowLg}>
      {belowLg ? (
        <BurgerWrapper>
          <BurgerMenu onClick={onMenuClick} />
        </BurgerWrapper>
      ) : null}
      <LogoWrapper>
        <SiteLogo onClick={onLogoClick} />
      </LogoWrapper>
      <Auth inline>
        <ScopeSelector belowLg={belowLg} />
        {!belowLg && currentUserRoles.includes(C2PORTAL_ROLE) ? (
          <ExternalLink
            trackCode={0}
            role="c2portal-link"
            text={intl.formatMessage(messages.c2Portal)}
            href={getC2PortalUrl()}
          />
        ) : null}
        {!belowLg && currentUserRoles.includes(DHLI_ROLE) ? (
          <ExternalLink
            trackCode={9}
            role="dhli-link"
            text={intl.formatMessage(messages.dhliPortal)}
            href={getDHLiUrl(isDemo)}
          />
        ) : null}
        {isReadOnly && !isDemo && (
          <ReadOnlyWrapper>
            <ReadOnlyIconWrapper>
              <SvgVisibilityOn />
            </ReadOnlyIconWrapper>
            <ReadOnlyMessageContainer>
              {intl.formatMessage(messages.readOnly)}
            </ReadOnlyMessageContainer>
          </ReadOnlyWrapper>
        )}
        {!belowLg && <UserIconSeparator />}
        <SupportCenterTopBarButton />
        <UserWrapper>
          <UserTopBar />
        </UserWrapper>
      </Auth>
    </TopBar>
  );
};

TopBarWithAuth.defaultProps = {
  onMenuClick: () => {},
  onSearchClickMobile: () => {},
  onLogoClick: () => {},
  onUserClick: () => {},
  onSearchTextChange: () => {},
  onSearchEnter: () => {},
  onSearchReset: () => {},
};

TopBarWithAuth.propTypes = {
  onMenuClick: PropTypes.func,
  onSearchClickMobile: PropTypes.func,
  onLogoClick: PropTypes.func,
  onSearchTextChange: PropTypes.func,
  onSearchEnter: PropTypes.func,
  onSearchReset: PropTypes.func,
  onUserClick: PropTypes.func,
  belowLg: PropTypes.bool,
};

export default TopBarWithAuth;
