import {
  AccountInteractions,
  accountEventNameToAccountEventContentMapper,
} from "./account/AccountEvents.constants";
import {
  AnalyticsInteractions,
  analyticsEventNameToAnalyticsEventContentMapper,
} from "./analytics-spent/AnalyticsSpentEvents.constants";
import {
  BillingInteractions,
  billingEventNameToBillingEventContentMapper,
} from "./billing/BillingEvents.constants";
import { bookingFinalizeEventNameToBookingFinalizeEventContentMapper } from "./booking-summary/BookingFinalizeEvents.constants";
import {
  DashboardInteractions,
  dashboardEventNameToDashboardEventContentMapper,
} from "./dashboard/DashboardEvents.constants";
import {
  DocumentsInteractions,
  documentsEventNameToDocumentsEventContentMapper,
} from "./documents/DocumentsEvents.constants";
import {
  ErrorsInteractions,
  errorsEventNameToErrorsEventContentMapper,
} from "./errors/ErrorsEvents.constants";
import {
  ExportQuotesInteractions,
  exportQuotesEventNameToExportQuotesEventContentMapper,
} from "./export-quotes/ExportQuotesEvents.constants";
import {
  ExportShipmentsInteractions,
  exportShipmentsEventNameToExportShipmentsEventContentMapper,
} from "./export-shipments/ExportShipmentsEvents.constants";
import {
  MapInteractionInteractions,
  mapInteractionEventNameToMapInteractionEventContentMapper,
} from "./map-interaction/MapInteractionEvents.constants";
import {
  MapTrackingInteractions,
  mapTrackingEventNameToMapTrackingEventContentMapper,
} from "./map-tracking/MapTrackingEvents.constants";
import {
  QuotesInteractions,
  quotesEventNameToQuotesEventContentMapper,
} from "./quotes/QuotesEvents.constants";
import {
  RoutingInteractions,
  routingEventNameToRoutingEventContentMapper,
} from "./routing/RoutingEvents.constants";
import {
  SaveYourSelectionInteractions,
  saveYourSelectionEventNameToEventContentMapper,
} from "./save-your-selection/SaveYourSelectionEvents.constants";
import {
  SelectQuoteInteractions,
  selectQuoteEventNameToEventContentMapper,
} from "./select-quote/SelectQuoteEvents.constants";
import {
  ServiceInteractions,
  serviceEventNameToRoutingEventContentMapper,
} from "./service/ServiceEvents.constants";
import {
  ShipmentDetailsInteractions,
  shipmentDetailsEventNameToEventContentMapper,
} from "./shipment-details/ShipmentDetailsEvents.constants";
import {
  ShipmentsInteractions,
  shipmentsEventNameToShipmentsEventContentMapper,
} from "./shipments/ShipmentsEvents.constants";
import {
  SummaryInteractions,
  summaryEventNameToSummaryEventContentMapper,
} from "./summary/SummaryEvents.constants";
import {
  ThankYouInteractions,
  thankYouEventNameToThankYouEventContentMapper,
} from "./thank-you/ThankYouEvents.constants";

export const contentInteractionEvent = "dhl_utf_contentInteraction";

export interface EventConfig {
  id: string;
  name: string;
}

export interface EventContentLex {
  name: string;
  type: string;
  interaction: string;
  position: string;
  context: string;
}

export type EventNameToContentMapperType<
  T extends Record<string, EventConfig>
> = {
  [key: string]: EventContentLex;
};

export interface DispatchInteractionEventProps {
  eventName: EventConfig;
  position?: number;
  eventNameDetails?: string;
}

export const eventNameToEventContentMapper = {
  ...accountEventNameToAccountEventContentMapper,
  ...analyticsEventNameToAnalyticsEventContentMapper,
  ...billingEventNameToBillingEventContentMapper,
  ...bookingFinalizeEventNameToBookingFinalizeEventContentMapper,
  ...dashboardEventNameToDashboardEventContentMapper,
  ...documentsEventNameToDocumentsEventContentMapper,
  ...errorsEventNameToErrorsEventContentMapper,
  ...exportQuotesEventNameToExportQuotesEventContentMapper,
  ...exportShipmentsEventNameToExportShipmentsEventContentMapper,
  ...mapInteractionEventNameToMapInteractionEventContentMapper,
  ...mapTrackingEventNameToMapTrackingEventContentMapper,
  ...quotesEventNameToQuotesEventContentMapper,
  ...routingEventNameToRoutingEventContentMapper(),
  ...saveYourSelectionEventNameToEventContentMapper,
  ...selectQuoteEventNameToEventContentMapper,
  ...serviceEventNameToRoutingEventContentMapper,
  ...shipmentDetailsEventNameToEventContentMapper,
  ...shipmentsEventNameToShipmentsEventContentMapper,
  ...summaryEventNameToSummaryEventContentMapper,
  ...thankYouEventNameToThankYouEventContentMapper,
};

export type EventName =
  | typeof AccountInteractions
  | typeof AnalyticsInteractions
  | typeof BillingInteractions
  | typeof DashboardInteractions
  | typeof DocumentsInteractions
  | typeof ErrorsInteractions
  | typeof ExportQuotesInteractions
  | typeof ExportShipmentsInteractions
  | typeof MapInteractionInteractions
  | typeof MapTrackingInteractions
  | typeof QuotesInteractions
  | typeof RoutingInteractions
  | typeof SaveYourSelectionInteractions
  | typeof SelectQuoteInteractions
  | typeof ServiceInteractions
  | typeof ShipmentDetailsInteractions
  | typeof ShipmentsInteractions
  | typeof SummaryInteractions
  | typeof ThankYouInteractions;
