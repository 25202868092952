import React from "react";
import { LogoSize } from "react-lib/es/atoms/Logo/Logo";
import { Link } from "react-router-dom";
import * as C from "./Logo.constants";
import * as S from "./Logo.styles";

const Logo: React.FC<C.LogoProps> = ({
  className,
  size = LogoSize.Large,
  dataTrack = 1,
  redirectLink = "/",
}) => (
  <Link
    className={className}
    data-track={dataTrack}
    to={redirectLink}
    aria-label="Logo - Index"
  >
    <S.StyledLogo size={size} />
  </Link>
);

Logo.propTypes = C.LogoPropTypes;

Logo.defaultProps = C.LogoDefaultProps;

export default Logo;
