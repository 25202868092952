import styled from "styled-components";
import { getRem, getRemMedia } from "../../utils/css";

const LogoWrapper = styled.span`
  display: inline-block;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  &:focus {
    outline: none;
  }
  width: ${getRem(150)};
  svg {
    width: ${getRem(130)};
    height: ${getRem(25)};
  }
  @media (min-width: ${getRemMedia(1024.2)}) {
    width: ${getRem(200)};
    svg {
      width: ${getRem(140)};
      height: ${getRem(28)};
    }
  }
`;

export default LogoWrapper;
