import SVGWrapper from "components/atoms/SVGWrapper";
import { Color } from "react-lib";
import styled from "styled-components";

export const VerifiedByDHLContainer = styled.div`
  font-size: 0.75rem;
  line-height: 1.33;
  color: ${Color.black45};
`;

export const StyledSVGWrapper = styled(SVGWrapper)`
  align-self: center;

  margin-right: 0.5rem;

  svg {
    fill: ${Color.black45};
    width: 1.25rem;
    height: 1.25rem;
  }
`;
