import { ReactComponent as DHLLogo } from "assets/icons/DHL_Logo.svg";
import LogoWrapper from "components/atoms/LogoWrapper";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const SiteLogo = (props) => {
  return (
    <LogoWrapper
      data-track={1}
      as={Link}
      to={props.link || "/"}
      aria-label="Logo - Index"
    >
      <DHLLogo onClick={() => props.onClick()} />
    </LogoWrapper>
  );
};

SiteLogo.defaultProps = {
  onClick: () => {},
};
SiteLogo.propTypes = {
  onClick: PropTypes.func,
  link: PropTypes.string,
};

export default SiteLogo;
