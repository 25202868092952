import {
  eventContentButtonInterObj,
  eventContentLinkInterObj,
} from "../../AdobeAnalytics.constants";
import * as C from "../ContentInteractionEvents.constants";

const prefix = "ShipmentsInteractions";

export const ShipmentsInteractions = {
  TABLE_SORTING: { id: `${prefix}_Sort by`, name: "Sort by" },
  REFRESH_TABLE: { id: `${prefix}_Refresh Table`, name: "Refresh Table" },
  TABLE_OPTIONS: { id: `${prefix}_Table options`, name: "Table options" },
  RESET_FILTERS: { id: `${prefix}_Reset Filter`, name: "Reset Filter" },
  FOLLOWING_SHIPMENT: {
    id: `${prefix}_Following Shipment`,
    name: "Following Shipment",
  },
  UNFOLLOWING_SHIPMENT: {
    id: `${prefix}_Unfollowing Shipment`,
    name: "Unfollowing Shipment",
  },
  SHARE_SHIPMENT: { id: `${prefix}_Share Shipment`, name: "Share Shipment" },
  INDIVIDUAL_SHIPMENT: {
    id: `${prefix}_Individual Shipment`,
    name: "Individual Shipment",
  },
  GET_A_QUOTE: { id: `${prefix}_Get a Quote`, name: "Get a Quote" },
  EXPORT_LIST: { id: `${prefix}_Export List`, name: "Export List" },
};

enum ShipmentsPosition {
  TABLE_HEADER = "Table Header",
  PAGE_HEADER = "Page Header",
}

const shipmentsListContext = "Shipment List";

export const shipmentsEventNameToShipmentsEventContentMapper: C.EventNameToContentMapperType<
  typeof ShipmentsInteractions
> = {
  [ShipmentsInteractions.TABLE_SORTING.id]: {
    name: ShipmentsInteractions.TABLE_SORTING.name,
    ...eventContentButtonInterObj,
    position: ShipmentsPosition.TABLE_HEADER,
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.REFRESH_TABLE.id]: {
    name: ShipmentsInteractions.REFRESH_TABLE.name,
    ...eventContentButtonInterObj,
    position: ShipmentsPosition.TABLE_HEADER,
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.TABLE_OPTIONS.id]: {
    name: ShipmentsInteractions.TABLE_OPTIONS.name,
    ...eventContentButtonInterObj,
    position: ShipmentsPosition.TABLE_HEADER,
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.RESET_FILTERS.id]: {
    name: ShipmentsInteractions.RESET_FILTERS.name,
    ...eventContentButtonInterObj,
    position: ShipmentsPosition.TABLE_HEADER,
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.FOLLOWING_SHIPMENT.id]: {
    name: ShipmentsInteractions.FOLLOWING_SHIPMENT.name,
    ...eventContentButtonInterObj,
    position: "0",
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.UNFOLLOWING_SHIPMENT.id]: {
    name: ShipmentsInteractions.UNFOLLOWING_SHIPMENT.name,
    ...eventContentButtonInterObj,
    position: "0",
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.SHARE_SHIPMENT.id]: {
    name: ShipmentsInteractions.SHARE_SHIPMENT.name,
    ...eventContentButtonInterObj,
    position: "0",
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.INDIVIDUAL_SHIPMENT.id]: {
    name: ShipmentsInteractions.INDIVIDUAL_SHIPMENT.name,
    ...eventContentLinkInterObj,
    position: "0",
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.GET_A_QUOTE.id]: {
    name: ShipmentsInteractions.GET_A_QUOTE.name,
    ...eventContentLinkInterObj,
    position: ShipmentsPosition.PAGE_HEADER,
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.EXPORT_LIST.id]: {
    name: ShipmentsInteractions.EXPORT_LIST.name,
    ...eventContentButtonInterObj,
    position: ShipmentsPosition.PAGE_HEADER,
    context: shipmentsListContext,
  },
};
