import { eventContentButtonInterObj } from "../../AdobeAnalytics.constants";
import * as C from "../ContentInteractionEvents.constants";

const prefix = "ServiceInteractions";

export const ServiceInteractions = {
  ADD_EXPORT_CUSTOMS_CLEARANCE: {
    id: `${prefix}_Add Export Customs Clearance`,
    name: "Add Export Customs Clearance",
  },
  REMOVE_EXPORT_CUSTOMS_CLEARANCE: {
    id: `${prefix}_Remove Export Customs Clearance`,
    name: "Remove Export Customs Clearance",
  },
  ADD_IMPORT_CUSTOMS_CLEARANCE: {
    id: `${prefix}_Add Import Customs Clearance`,
    name: "Add Import Customs Clearance",
  },
  REMOVE_IMPORT_CUSTOMS_CLEARANCE: {
    id: `${prefix}_Remove Import Customs Clearance`,
    name: "Remove Import Customs Clearance",
  },
  NUMBER_OF_LINES: { id: `${prefix}_Number of Lines`, name: "Number of Lines" },
  INSURANCE_VALUE: { id: `${prefix}_Insurance Value`, name: "Insurance Value" },
  ADD_INSURANCE: { id: `${prefix}_Add Insurance`, name: "Add Insurance" },
  REMOVE_INSURANCE: {
    id: `${prefix}_Remove Insurance`,
    name: "Remove Insurance",
  },
  NEUTRALIZE_VIA_BIOFUEL: {
    id: `${prefix}_Neutralize via Biofuel`,
    name: "Neutralize via Biofuel",
  },
  OFFSET_VIA_CARBON_CREDITS: {
    id: `${prefix}_Offset via Carbon Credits`,
    name: "Offset via Carbon Credits",
  },
  ADD_CLIMATE_NEUTRAL: {
    id: `${prefix}_Add Climate Neutral`,
    name: "Add Climate Neutral",
  },
  REMOVE_CLIMATE_NEUTRAL: {
    id: `${prefix}_Remove Climate Neutral`,
    name: "Remove Climate Neutral",
  },
  UNKNOWN_SHIPPER: { id: `${prefix}_Unknown Shipper`, name: "Unknown Shipper" },
  KNOWN_SHIPPER: { id: `${prefix}_Known Shipper`, name: "Known Shipper" },
  INCOTERMS: { id: `${prefix}_Incoterms`, name: "Incoterms" },
  COMMENTS: { id: `${prefix}_Comments`, name: "Comments" },
  SAVE_AND_CONTINUE: {
    id: `${prefix}_Save and Continue`,
    name: "Save and Continue",
  },
};

enum ServicePosition {
  CUSTOMS_CLEARANCE = "Customs Clearance",
  CARGO_INSURANCE = "Cargo Insurance",
  CLIMATE_NEUTRAL = "Climate Neutral",
  TSA_STATUS = "TSA Status",
  INCOTERMS = "Incoterms",
  COMMENTS = "Comments",
  FOOTER = "Footer",
}

const serviceContext = "Service";

export const serviceEventNameToRoutingEventContentMapper: C.EventNameToContentMapperType<
  typeof ServiceInteractions
> = {
  [ServiceInteractions.ADD_EXPORT_CUSTOMS_CLEARANCE.id]: {
    name: ServiceInteractions.ADD_EXPORT_CUSTOMS_CLEARANCE.name,
    ...eventContentButtonInterObj,
    position: ServicePosition.CUSTOMS_CLEARANCE,
    context: serviceContext,
  },
  [ServiceInteractions.REMOVE_EXPORT_CUSTOMS_CLEARANCE.id]: {
    name: ServiceInteractions.REMOVE_EXPORT_CUSTOMS_CLEARANCE.name,
    ...eventContentButtonInterObj,
    position: ServicePosition.CUSTOMS_CLEARANCE,
    context: serviceContext,
  },
  [ServiceInteractions.ADD_IMPORT_CUSTOMS_CLEARANCE.id]: {
    name: ServiceInteractions.ADD_IMPORT_CUSTOMS_CLEARANCE.name,
    ...eventContentButtonInterObj,
    position: ServicePosition.CUSTOMS_CLEARANCE,
    context: serviceContext,
  },
  [ServiceInteractions.REMOVE_IMPORT_CUSTOMS_CLEARANCE.id]: {
    name: ServiceInteractions.REMOVE_IMPORT_CUSTOMS_CLEARANCE.name,
    ...eventContentButtonInterObj,
    position: ServicePosition.CUSTOMS_CLEARANCE,
    context: serviceContext,
  },
  [ServiceInteractions.NUMBER_OF_LINES.id]: {
    name: ServiceInteractions.NUMBER_OF_LINES.name,
    ...eventContentButtonInterObj,
    position: ServicePosition.FOOTER,
    context: serviceContext,
  },
  [ServiceInteractions.INSURANCE_VALUE.id]: {
    name: ServiceInteractions.INSURANCE_VALUE.name,
    ...eventContentButtonInterObj,
    position: ServicePosition.CARGO_INSURANCE,
    context: serviceContext,
  },
  [ServiceInteractions.ADD_INSURANCE.id]: {
    name: ServiceInteractions.ADD_INSURANCE.name,
    ...eventContentButtonInterObj,
    position: ServicePosition.CARGO_INSURANCE,
    context: serviceContext,
  },
  [ServiceInteractions.REMOVE_INSURANCE.id]: {
    name: ServiceInteractions.REMOVE_INSURANCE.name,
    ...eventContentButtonInterObj,
    position: ServicePosition.CARGO_INSURANCE,
    context: serviceContext,
  },
  [ServiceInteractions.NEUTRALIZE_VIA_BIOFUEL.id]: {
    name: ServiceInteractions.NEUTRALIZE_VIA_BIOFUEL.name,
    ...eventContentButtonInterObj,
    position: ServicePosition.CLIMATE_NEUTRAL,
    context: serviceContext,
  },
  [ServiceInteractions.OFFSET_VIA_CARBON_CREDITS.id]: {
    name: ServiceInteractions.OFFSET_VIA_CARBON_CREDITS.name,
    ...eventContentButtonInterObj,
    position: ServicePosition.CLIMATE_NEUTRAL,
    context: serviceContext,
  },
  [ServiceInteractions.ADD_CLIMATE_NEUTRAL.id]: {
    name: ServiceInteractions.ADD_CLIMATE_NEUTRAL.name,
    ...eventContentButtonInterObj,
    position: ServicePosition.CLIMATE_NEUTRAL,
    context: serviceContext,
  },
  [ServiceInteractions.REMOVE_CLIMATE_NEUTRAL.id]: {
    name: ServiceInteractions.REMOVE_CLIMATE_NEUTRAL.name,
    ...eventContentButtonInterObj,
    position: ServicePosition.CLIMATE_NEUTRAL,
    context: serviceContext,
  },
  [ServiceInteractions.UNKNOWN_SHIPPER.id]: {
    name: ServiceInteractions.UNKNOWN_SHIPPER.name,
    ...eventContentButtonInterObj,
    position: ServicePosition.TSA_STATUS,
    context: serviceContext,
  },

  [ServiceInteractions.KNOWN_SHIPPER.id]: {
    name: ServiceInteractions.KNOWN_SHIPPER.name,
    ...eventContentButtonInterObj,
    position: ServicePosition.TSA_STATUS,
    context: serviceContext,
  },
  [ServiceInteractions.INCOTERMS.id]: {
    name: ServiceInteractions.INCOTERMS.name,
    ...eventContentButtonInterObj,
    position: ServicePosition.INCOTERMS,
    context: serviceContext,
  },
  [ServiceInteractions.COMMENTS.id]: {
    name: ServiceInteractions.COMMENTS.name,
    ...eventContentButtonInterObj,
    position: ServicePosition.COMMENTS,
    context: serviceContext,
  },
  [ServiceInteractions.SAVE_AND_CONTINUE.id]: {
    name: ServiceInteractions.SAVE_AND_CONTINUE.name,
    ...eventContentButtonInterObj,
    position: ServicePosition.FOOTER,
    context: serviceContext,
  },
};
