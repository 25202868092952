import PropTypes from "prop-types";
import { WeakValidationMap } from "react";
import { LogoSize } from "react-lib/es/atoms/Logo/Logo";

export interface LogoProps {
  className?: string;
  redirectLink?: string;
  size?: LogoSize;
  dataTrack?: number;
}

export const LogoPropTypes: WeakValidationMap<LogoProps> = {
  redirectLink: PropTypes.string,
  className: PropTypes.string,
  dataTrack: PropTypes.number,
  size: PropTypes.oneOf(Object.values(LogoSize)),
};

export const LogoDefaultProps: LogoProps = {
  redirectLink: "/",
  dataTrack: 1,
  size: LogoSize.LARGE,
};
